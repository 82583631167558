<template>
  <div class="viewscreens-content">
    <a-row
      class="d-flex align-items-center mb-4"
      style="margin: -30px; padding: 8px 30px 22px 30px; background: #fff"
    >
      <a-col :span="10">
        <h3 class="mb-0" style="font-size: 20px">Main</h3>
      </a-col>
      <a-col :span="14" class="text-right">
        <a-button
          v-if="permissionViewscreenCreate"
          type="primary"
          size="large"
          style="font-size: 16px; font-weight: 300; border-radius: 2px"
          @click="handleCreateViewScreen"
        >
          <a-icon type="plus" /> Create new
        </a-button>
      </a-col>
    </a-row>
    <div class="viewscreen-tabs">
      <div ref="tabs" class="viewscreen-tabs__container">
        <a-tabs
          v-model="activeTabId"
          type="card"
          class="viewscreen-tabs__card"
          @change="onTabChange"
        >
          <template #tabBarExtraContent>
            <a-popover
              placement="bottomRight"
              :getPopupContainer="getPopupContainer"
              style="margin-bottom: 1px;"
            >
              <template #content>
                <div class="viewscreen-tabs__project-search">
                  <a-input v-model="tabsSearch" placeholder="Project" />
                  <div class="viewscreen-tabs__project-search__items-container">
                    <div
                      v-for="tab in menuTabs"
                      :key="tab.id"
                      class="ant-dropdown-menu-item"
                      @click="onTabChange(tab.id)"
                    >
                      {{ tab.name }}
                    </div>
                  </div>
                </div>
              </template>
              <a-button size="large" icon="menu" />
            </a-popover>
          </template>

          <a-tab-pane v-for="tab in tabs" :key="tab.id">
            <template #tab>
              <a-dropdown
                :trigger="['contextmenu']"
                :getPopupContainer="getPopupContainer"
              >
                <template #overlay>
                  <a-menu>
                    <a-menu-item
                      v-if="!tab.is_pinned"
                      :key="'pin'"
                      :disabled="tab.is_default || !permissionViewscreenEdit"
                      @click="(info) => handlePinTab({ key: info.key, tab })"
                    >
                      <span>Pin</span>
                    </a-menu-item>
                    <a-menu-item
                      v-else
                      :key="'unpin'"
                      :disabled="tab.is_default || !permissionViewscreenEdit"
                      @click="(info) => handlePinTab({ key: info.key, tab })"
                    >
                      <span>Detach</span>
                    </a-menu-item>
                  </a-menu>
                </template>
                <div
                  class="tab-item__drag-handle"
                  :class="{ pinned: tab.is_pinned || false }"
                >
                  <a-icon
                    v-if="tab.is_pinned || false"
                    type="pushpin"
                    theme="filled"
                  />
                  <span>{{ tab.name }}</span>
                </div>
              </a-dropdown>
            </template>

            <template v-show="!tabsLoading">
              <div class="viewscreen-tabs__apps">
                <div
                  ref="apps-container"
                  class="viewscreen-tabs__apps-container"
                >
                  <a-icon
                    @click="handleEditViewscreen"
                    type="setting"
                    class="viewscreen-tabs__settings-icon"
                    :class="{disabled : isTabDefault}"
                  />
                  <AppSelect
                    v-model="filters.applicationId"
                    :apps="activeTabApps"
                    @change="handleAppChange"
                    class="mr-3"
                    :disabled="tabsLoading"
                    size="small"
                    hideTitle
                    style="width: 220px"
                  />
                  <a-select
                    :value="selectedLang"
                    size="small"
                    :disabled="appsLoading"
                    :showArrow="false"
                    @change="handleLangChange"
                    class="lang-select"
                  >
                    <a-select-option
                      v-for="lang in viewscreenLangs"
                      :key="lang.value"
                    >
                      {{ lang.label }}
                    </a-select-option>
                  </a-select>
                </div>
                <div>
                  <a-button v-if="!isPublishReady" :loading="updateTabLoading" disabled class="mr-2">
                    Published <a-icon type="check-circle" />
                  </a-button>
                  <a-popconfirm v-else
                    title="Sure to publish all?"
                    @confirm="publishAllSections"
                  >
                    <a-button type="primary" :loading="updateTabLoading" class="mr-2 text-white all-published">
                      Publish all
                    </a-button>
                    </a-popconfirm>
                  <a-button
                  v-if="permissionSectionCreate"
                    type="primary"
                    icon="plus"
                    :loading="updateTabLoading"
                    @click="createSection"
                  >
                    Section
                  </a-button>
                </div>
              </div>
              <Spinner v-if="tabsLoading" />
              <div v-else>
                <transition-group v-if="activeTabSections && activeTabSections?.length" tag="div" class="grid" type="transition" name="flip-grid">
                  <div
                    v-for="(section, index) in activeTabSections"
                    :key="section.id"
                    class="viewscreen-section"
                  >
                    <PromoImagesSection
                      v-if="section?.type === 'promo'"
                      :key="section?.id"
                      :promoImagesData="section?.items || []"
                      :index="index"
                      :max-index="activeTabSections?.length"
                      :selected-lang="selectedLangShort"
                      :langs="viewscreenLangs"
                      :show-label="section?.show_label"
                      :is-active="section?.is_active"
                      :localizations="section.localizations"
                      :section-id="section.id"
                      :activeTabId="activeTabId"
                      :isEditAvailable="permissionSectionEdit"
                      @editSection="openEditSection"
                      @changeStatusSection="changeStatusSection"
                      @moveDownSection="sectionShift"
                      @moveUpSection="sectionShift"
                      @toggleIsPublished="toggleIsPublished"
                    />
                    <ShortcutSection
                      v-if="
                        [
                          'shortcut_small',
                          'shortcut_medium',
                          'shortcut_large',
                        ].includes(section.type)
                      "
                      :key="section?.id"
                      :shortcutSectionData="section?.items || []"
                      :index="index"
                      :max-index="activeTabSections?.length"
                      :selected-lang="selectedLangShort"
                      :langs="viewscreenLangs"
                      :show-label="section?.show_label"
                      :is-active="section?.is_active"
                      :localizations="section.localizations"
                      :section-id="section.id"
                      :shortcut-type="section.type"
                      :isEditAvailable="permissionSectionEdit"
                      @editSection="openEditSection"
                      @changeStatusSection="changeStatusSection"
                      @moveDownSection="sectionShift"
                      @moveUpSection="sectionShift"
                      @toggleIsPublished="toggleIsPublished"
                    />
                    <TopMatchesSection
                      v-if="section?.type === 'topmatches'"
                      :key="section?.id"
                      :topMatchesData="section?.items || []"
                      :index="index"
                      :max-index="activeTabSections?.length"
                      :selected-lang="selectedLangShort"
                      :langs="viewscreenLangs"
                      :show-label="section?.show_label"
                      :is-active="section?.is_active"
                      :localizations="section.localizations"
                      :section-id="section.id"
                      :limits="section.limits"
                      :viewscreenId="activeTabId"
                      :isEditAvailable="permissionSectionEdit"
                      @editSection="openEditSection"
                      @changeStatusSection="changeStatusSection"
                      @moveDownSection="sectionShift"
                      @moveUpSection="sectionShift"
                      @toggleIsPublished="toggleIsPublished"
                    />
                    <!-- <InformerSection
                      v-if="section?.type === 'informer'"
                      :key="section?.id"
                      :informerSectionData="section?.items || []"
                      :index="index"
                      :max-index="activeTabSections?.length"
                      :selected-lang="selectedLangShort"
                      :show-label="section?.show_label"
                      :is-active="section?.is_active"
                      :localizations="section.localizations"
                      :section-id="section.id"
                      :isEditAvailable="permissionSectionEdit"
                      @editSection="openEditSection"
                      @changeStatusSection="changeStatusSection"
                      @moveDownSection="sectionShift"
                      @moveUpSection="sectionShift"
                      @toggleIsPublished="toggleIsPublished"
                    /> -->
                  </div>
                </transition-group>
                <div v-else class="empty-section">No sections available</div>
              </div>
            </template>
          </a-tab-pane>
        </a-tabs>
      </div>
      <a-modal
        v-model="isSectionModalOpen"
        :title="modalSectionTitle"
        :footer="null"
        class="section-modal"
        width="445px"
        height="330px"
        :destroyOnClose="true"
      >
        <SectionModal
          :sectionData="sectionToEdit"
          :langs="viewscreenLangs"
          :viewscreenId="activeTabId"
          @closeSectionModal="closeSectionModal"
          @createSection="handleCreateSection"
          @editSection="handleEditSection"
          @deleteSectionModal="handleDeleteSection"
        />
      </a-modal>
      <a-modal
        v-model="isDeleteViewscreenOpen"
        title="Are you sure you want to delete this viewscreen?"
        :destroyOnClose="true"
      >
      </a-modal>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import ViewscreenService from '@/services/api/apiViewscreenService'
import AppSelect from '@/components/custom/filters/appSelect.vue'
import PromoImagesSection from '@/views/apps/viewscreens/components/promoImagesSection.vue'
import Spinner from '@/views/apps/main/components/spinner.vue'
import ShortcutSection from '@/views/apps/viewscreens/components/shortcutSection.vue'
// import InformerSection from '@/views/apps/viewscreens/components/informerSection.vue'
import TopMatchesSection from '@/views/apps/viewscreens/components/topMatchesSection.vue'
import accessMix from '@/services/accessmix.js'
import SectionModal from '@/views/apps/viewscreens/modals/sectionModal.vue'

import Sortable from 'sortablejs'

export default {
  name: 'Viewscreen-content',
  mixins: [accessMix],
  props: [],
  components: {
    AppSelect,
    PromoImagesSection,
    Spinner,
    SectionModal,
    ShortcutSection,
    TopMatchesSection,
    // InformerSection,
  },
  data: () => ({
    tabs: [],
    activeTabId: '',
    drag: false,
    updateTabLoading: false,
    activeTabData: { sections: [] },
    widthContainer: 450,
    langs: [],
    appsLoading: false,
    tabsLoading: false,
    filters: {
      lang: 'ru',
      applicationId: null,
    },
    selectedLang: '0',
    viewscreenLangs: [
      { label: 'Russian', value: '0', shortcut: 'ru' },
      { label: 'Kazakh', value: '1', shortcut: 'kk' },
      { label: 'English', value: '2', shortcut: 'en' },
      { label: 'Chinese', value: '3', shortcut: 'cn' },
      { label: "O'zbek", value: '22', shortcut: 'uz' },
    ],
    isSectionModalOpen: false,
    isDeleteViewscreenOpen: false,
    isPublishReady: false,
    sectionToEdit: null,
    menuTabs: '',
    tabsSearch: '',
  }),
  watch: {
    tabsSearch(newValue) {
      this.getMenuTabs(newValue)
    },
    activeTabApps(newValue, oldValue) {
      if (newValue?.length && newValue !== oldValue) {
        this.filters.applicationId = newValue[0].application_id
      }
    },
  },
  computed: {
    ...mapState(['user']),
    permissionViewscreenCreate() {
      return this.checkPermissions(this.user.permissions, 'viewscreens', 'create')
    },
    permissionViewscreenEdit() {
      return this.checkPermissions(this.user.permissions, 'viewscreens', 'edit')
    },
    permissionSectionCreate() {
      return this.checkPermissions(this.user.permissions, 'sections', 'create')
    },
    permissionSectionEdit() {
      return this.checkPermissions(this.user.permissions, 'sections', 'edit')
    },
    modalSectionTitle() {
      if (this.sectionToEdit) {
        return 'Edit section'
      }
      return 'Add section'
    },
    activeTab() {
      return this.tabs.find((tab) => tab.id === this.activeTabId)
    },
    activeTabSections() {
      if (this.activeTabData) {
        return this.activeTabData.sections
      }
      return null
    },
    // TODO: EGOR BUG APPS
    activeTabApps() {
      if (this.tabs.length && !this.isTabDefault) {
        return this.tabs.find(tab => tab.id === this.activeTabId).applications
      }
      return null
    },
    selectedLangShort() {
      const selectedLangInfo = this.viewscreenLangs.find(lang => lang.value === this.selectedLang)
      return selectedLangInfo ? selectedLangInfo.shortcut : 'en'
    },
    isTabDefault() {
      if (this.activeTabData) {
        return this.activeTabData.is_default
      }
      return false
    },
  },
  methods: {
    getPopupContainer() {
      return document.body
    },
    handleCreateViewScreen() {
      this.$router.push('/main/viewscreen-form/create-viewscreen')
    },
    handleEditViewscreen() {
      if (this.isTabDefault) {
        return
      }
      this.$router.push(
        `/main/viewscreen-form/edit-viewscreen/${this.activeTabId}`,
      )
    },
    async fetchTabs(name, loading) {
      if (loading) {
        this.tabsLoading = true
      }
      try {
        const response = await ViewscreenService.getViewscreenTabs(name)
        return response.data.data || []
      } finally {
        this.tabsLoading = false
      }
    },
    async getViewsreenTabs() {
      const tabs = await this.fetchTabs(name, true)
      if (tabs.length) {
        this.tabs = tabs
        this.activeTabId = tabs[0].id
      }
    },
    async getMenuTabs(name) {
      const tabs = await this.fetchTabs(name)
      this.menuTabs = tabs
    },
    async fetchViewscreenTab(id, langId, bundleName) {
      this.tabsLoading = true
      try {
        const response = await ViewscreenService.getViewscreenTab(id, langId, bundleName)
        if (response.data.data) {
          this.activeTabData = response.data.data
          this.isPublishReady = this.activeTabData.is_publish_ready
        }
      } finally {
        this.tabsLoading = false
      }
    },
    onTabChange(tabId) {
      this.activeTabData = null
      this.activeTabId = tabId
      this.filters.applicationId = null
      this.fetchViewscreenTab(this.activeTabId, this.selectedLang, this.activeTabApps && this.activeTabApps.length ? this.activeTabApps[0].bundle_name : undefined)
    },
    async changeStatusSection(sectionId, sectionStatus) {
      try {
        this.loading = true
        const sectionPayload = {
          is_active: sectionStatus,
        }
        const sectionResponse = await ViewscreenService.updateViewscreenSection(
          this.activeTabId,
          sectionId,
          sectionPayload,
        )
        if (sectionResponse.data) {
          this.$notification.success({
            message: 'Active status changed successfully',
          })
          this.toggleIsPublished()
        }
      } catch (err) {
        this.$notification.error({
          message: err.response.data.error.message,
        })
        this.loading = false
      } finally {
        this.loading = false
      }
    },
    appTextClass(app) {
      switch (app.platform) {
        case 'ios':
          return 'text-primary'
        case 'android':
          return 'text-success'
        case 'web':
          return 'text-default'
      }
    },
    appIcon(app) {
      switch (app.platform) {
        case 'ios':
          return 'apple'
        case 'android':
          return 'android'
        case 'web':
          return 'global'
      }
    },
    handleLangChange(lang) {
      this.selectedLang = lang
      this.fetchViewscreenTab(this.activeTabId, lang)
    },
    handleAppChange() {
      const newBundleName = this.activeTabApps.find((app) => {
        return app.application_id === this.filters.applicationId
      }).bundle_name
      this.fetchViewscreenTab(this.activeTabId, this.selectedLang, newBundleName)
    },
    openSectionModal() {
      this.isSectionModalOpen = true
    },
    createSection() {
      this.sectionToEdit = null
      this.openSectionModal()
    },
    openEditSection(sectionId) {
      this.sectionToEdit = this.activeTabSections.find(
        (section) => section.id === sectionId,
      )
      this.openSectionModal()
    },
    closeSectionModal() {
      this.isSectionModalOpen = false
    },
    handleCreateSection(newSection) {
      this.isSectionModalOpen = false
      this.sectionToEdit = null
      this.activeTabSections.unshift(newSection)
      this.toggleIsPublished()
    },
    handleEditSection(editedSection) {
      this.isSectionModalOpen = false
      this.sectionToEdit = null
      this.activeTabSections[
        this.activeTabSections.findIndex(
          (section) => section.id === editedSection.id,
        )
      ] = editedSection
      this.activeTabSections.splice()
      this.toggleIsPublished()
    },
    handleDeleteSection(deletedId) {
      this.isSectionModalOpen = false
      this.sectionToEdit = null
      this.activeTabSections.splice(
        this.activeTabSections.findIndex((section) => section.id === deletedId),
        1,
      )
      this.toggleIsPublished()
    },
    async sectionShift(sectionId, value) {
      const indexSection = this.activeTabData.sections.findIndex(
        (section) => section.id === sectionId,
      )
      await this.moveArrayItemToNewIndex(
        this.activeTabData.sections,
        indexSection,
        indexSection + value,
      )
      this.sortSections()
    },
    moveArrayItemToNewIndex(arr, oldIndex, newIndex) {
      if (newIndex >= arr.length) {
        let k = newIndex - arr.length + 1
        while (k--) {
          arr.push(undefined)
        }
      }
      arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0])
      console.log(arr)
      return arr
    },
    initDraggableTabs() {
      const tabsContainerQuery = document.querySelector(
        '.ant-tabs-nav.ant-tabs-nav-animated',
      )
      const tabsContainer = tabsContainerQuery.children[0]
      if (!tabsContainer) {
        return
      }

      Sortable.create(tabsContainer, {
        direction: 'horizontal',
        filter: '.pinned',
        onEnd: ({ oldIndex, newIndex }) => {
          if (newIndex >= this.tabs.length) {
            throw new Error('How did this happen?')
          }

          const pinnedCount = this.tabs.filter((tab) => tab.is_pinned).length

          const elementToShift = this.tabs.splice(oldIndex, 1)[0]

          if (newIndex < pinnedCount) {
            elementToShift.is_pinned = true
            this.changePinTab(elementToShift.id, 'pin')
          }
          this.tabs.splice(newIndex, 0, elementToShift)
          this.sortViewscreens()
        },
      })
    },
    handlePinTab(item) {
      this.changePinTab(item.tab.id, item.key)
    },
    async changePinTab(tabId, action) {
      try {
        const data = {
          is_pinned: action === 'pin',
        }
        const response = await ViewscreenService.updateViewscreenTab(
          tabId,
          data,
        )
        if (response.data.data) {
          const message =
            action === 'pin'
              ? 'Tab has been successfully pinned'
              : 'Tab has been successfully unpinned'
          this.$notification.success({
            message: message,
          })
          await this.getViewsreenTabs()
        }
      } catch (error) {
        this.$notification.error({
          message: 'Error while updating tab',
          description: error.message,
        })
      }
    },
    toggleIsPublished () {
      this.isPublishReady = true
    },
    async publishAllSections() {
      try {
        const response = await ViewscreenService.publishViewscreenSections(
          this.activeTabId,
        )
        if (response.data.data) {
          this.$notification.success({
            message: 'All sections have been successfully published',
          })
          this.isPublishReady = false
        }
      } catch (error) {
        this.$notification.error({
          message: 'Error while updating tab',
          description: error.message,
        })
      }
    },
    async sortSections() {
      const sectionSort = this.activeTabSections.map(
        (section, index, sectionArr) => {
          return {
            id: section.id,
            sort: sectionArr.length - index,
          }
        },
      )

      if (!sectionSort.length) return

      const sort = { sort: sectionSort }
      try {
        await ViewscreenService.sortSections(sort)
        this.$notification.success({
          message: 'Sucessfully sorted',
        })
        this.toggleIsPublished()
      } catch (error) {
        console.log(error)
      }
    },
    async sortViewscreens() {
      const viewscreensSort = this.tabs.map((tab, index, tabArr) => {
        return {
          id: tab.id,
          sort: tabArr.length - index,
        }
      })
      const sort = { sort: viewscreensSort }
      try {
        await ViewscreenService.sortViewscreens(sort)
        this.$notification.success({
          message: 'Sucessfully sorted',
        })
      } catch (error) {
        console.log(error)
      }
      this.drag = false
    },
  },
  async created() {
    await this.getViewsreenTabs()
    if (
      this.$route.params.activeTabId &&
      !isNaN(this.$route.params.activeTabId)
    ) {
      this.activeTabId = Number(this.$route.params.activeTabId)
    }
    await this.fetchViewscreenTab(this.activeTabId, this.selectedLang, this.activeTabApps && this.activeTabApps.length ? this.activeTabApps[0].bundle_name : undefined)
    this.menuTabs = this.tabs
  },
  async mounted() {
    this.initDraggableTabs()
  },
}
</script>
<style lang="scss">
.viewscreen-tabs {
  &__container {
    overflow: hidden;

    & > .ant-tabs-card > .ant-tabs-content {
      margin-top: -17px;
    }

    & > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
      border: solid #e4e9f0;
      border-width: 0 1px 1px 1px;
      border-radius: 0 4px 4px 4px;
      background: #fff;
      padding: 16px;
    }

    & .ant-tabs-content > .ant-tabs-tabpane-inactive {
      border: none !important;
    }

    & > .ant-tabs-card > .ant-tabs-bar {
      display: flex;
      border-color: #fff;

      & .ant-tabs-extra-content {
        order: 1;
        float: none !important;
        display: flex;
        margin-bottom: -1px;
      }
    }

    .all-published {
      background-color: #34C759 !important;
      border-color: #34C759 !important;
      transition: background-color 0.3s ease;
      &:hover {
        background-color: #5CD97A !important;
      }
    }

    .lang-select .ant-select-selection {
      border: 0 !important;
      color: #1890ff;
      min-width: 80px;
    }

    & > .ant-tabs-card > .ant-tabs-bar {
      justify-content: space-between;
      color: #595959;
      border-color: transparent;
      background: transparent;

      &:not(.ant-tabs-tab-active) {
        background-color: #f5f5f5 !important;
      }
    }

    & > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
      border-color: #fff;
      background: #fff;
    }

    & .ant-tabs-tab {
      margin-right: 2px !important;
    }
  }

  &__settings-icon {
    color: #1890ff;
    font-size: 16px;
    margin-right: 12px;
    transition: all 0.3s ease;

    &:hover {
      cursor: pointer;
      transform: scale(1.2) rotate(90deg);
    }
  }
  &__settings-icon.disabled {
  color: #9e9e9e;
  cursor: not-allowed;
  transition: none;

  &:hover {
    background-color: #ffffff;
    transform: none;
  }
}

  &__apps {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  &__apps-container {
    display: flex;
    align-items: center;
  }

  .tab-item {
    &__drag-handle {
      padding: 0 16px;
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 40px;

      & > span {
        user-select: none;
      }
    }
  }

  .ant-tabs-tab {
    padding: 0 !important;
  }

  &__project-search {
    min-width: 200px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    &__items-container {
      padding-top: 8px;
      border-top: 1px solid #ededed;
      max-height: 200px;
      overflow-y: auto;
    }
  }
}

.empty-section {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #595959;
  font-size: 20px;
  padding: 20px;
}

.ant-popover-inner-content {
  width: 200px;
}
</style>
